<template>
    <div class="asset-departmental-assets-layout">
        <component
            v-bind:is="currentComponent"
            :assetMaintenance="assetMaintenance"
            :assetFlow="assetFlow"
            @goDepartmentFlow="goDepartmentFlow"
            @goAssetMaintenance="goAssetMaintenance"
            @backList="backList"
            @setPageInitTabs="setPageInitTabs"
        ></component>
    </div>
</template>
<script>
    import List from './AssetDepartmentalAssets/index.vue';
    import AssetMaintenance from './AssetDepartmentalAssets/AssetMaintenance.vue';
    import DepartmentalAssetFlow from './AssetDepartmentalAssets/DepartmentalAssetFlow.vue';

    export default {
        name: "AssetDepartmentalAssetsScrollWrapper",
        components: {
            List,
            AssetMaintenance,
            DepartmentalAssetFlow
        },
        data () {
            return {
                comList: ['List', 'AssetMaintenance', 'DepartmentalAssetFlow'],
                idx: 0,
                assetMaintenance: {},
                assetFlow: {},
                type: 'list'
            }
        },
        computed: {
            currentComponent () {
                return this.comList[this.idx];
            }
        },
        activated() {
            this.setGlobalPageType(this.type)
        },
        methods: {
            /**
             * goAssetMaintenance 资产维护
             * goDepartmentFlow 流水
             * backList 列表页
             * */
            goAssetMaintenance (data) {
                Object.keys(data).forEach((key) => {
                    this.assetMaintenance[key] = data[key];
                })
                console.log(this.assetMaintenance,'this.assetMaintenance123')
                this.idx = 1;
                this.setGlobalPageType('detail')
            },
            goDepartmentFlow (data) {
                Object.keys(data).forEach((key) => {
                    this.assetFlow[key] = data[key];
                })

                this.idx = 2;
                this.setGlobalPageType('detail')
            },
            backList () {
                this.idx = 0;
                this.setGlobalPageType('list')
            },
            setGlobalPageType (type) {
                this.type = type
                this.$eventDispatch('setGlobalPageType', type)
            },
            setPageInitTabs(){
                this.setGlobalPageType('list')
                this.idx = 0;
            }
        }
    }
</script>
<style lang="scss" scoped></style>
