<template>
    <div>
        <div v-if="!errorShow">
            <loading :loadingShow="loadingShow"></loading>
            <div
                v-if="!loadingShow"
                class="content-main"
            >
                <expand-filter
                    :closeWidth="170"
                    :formData="formData"
                    plat-type="1"
                    @changeBtnFormType="changeBtnFormType"
                    @clickBtn="clickBtn"
                ></expand-filter>
                <div class="title">
                    资产总金额：{{
                        totalMoney ? totalMoney.toLocaleString() : ""
                    }}元
                </div>
                <table-data
                    ref="table"
                    v-loading="loadingTable"
                    :config="table_config"
                    :tableData="table_data"
                    :backgroundHeader="backgroundHeader"
                    :headerColor="headerColor"
                >
                    <template v-slot:operation="slotData">
                        <el-button
                            @click="goAssetMaintenance(slotData.data)"
                            v-hasPermi="['assetDepartmentalAssets:protect']"
                            >资产维护</el-button
                        >
                        <el-button
                            @click="goDepartmentFlow(slotData.data)"
                            v-hasPermi="['assetDepartmentalAssets:waterRecord']"
                            >查看流水</el-button
                        >
                    </template>
                </table-data>
                <pagination
                    :total="total"
                    :page.sync="listQuery.pageNum"
                    :limit.sync="listQuery.pageRow"
                    @pagination="getList"
                />
            </div>
        </div>
        <error :errorShow="errorShow"></error>
    </div>
</template>
<script>
import {
    // 业务组件
    DialogWrapper,
    Pagination,
    // 功能组件
    Error,
    Loading,
} from "common-local";
// Ajax 请求
import TableData from "../Sub/TableData"
import {SystemModel} from "@/models/System.js";
import ExpandFilter from "../Sub/ExpandFilter";
import onResize from "@/mixins/onResize";
import RecordsList from '@/libs/pageListOptimal.js';
export default {
    mixins: [onResize],
    name: "AssetDepartmentList",
    components: {
        TableData,
        DialogWrapper,
        Pagination,
        // 功能组件
        Error,
        Loading,
        ExpandFilter
    },
    data() {
        return {
            loadingTable: false,
            totalMoney: "32242424", //资产总金额
            total: 0,
            listQuery: {
                deptId: "",
                deptName: "",
                schoolId: "",
                productId: "cloud_campus",
                pageNum: 1,
                pageRow: 20,
            },
            // 头部筛选
            formData: {
                formInline: {
                    deptName: "",
                },
                data: [
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "部门名称",
                        key: "deptName",
                        list: [],
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                        auth: ["assetDepartmentalAssets:list"],
                    },
                ]
            },
            // 表格配置
            table_config: {
                thead: [
                    {
                        label: "部门",
                        prop: "deptName",
                        className: 'text-spacing-reduction',
                        align: 'center'
                    },
                    {
                        label: "资产总数",
                        prop: "fixedCount",
                        align: "center",
                        labelWidth: "150",
                    },
                    {
                        type: "function",
                        label: "资产总额",
                        prop: "fixedTotal",
                        labelWidth: "150",
                        align: "right",
                        callBack(row) {
                            if (row.fixedTotal) {
                                return row.fixedTotal.toLocaleString();
                            } else {
                                return 0;
                            }
                        },
                    },
                    {
                        label: "耗材总数",
                        prop: "consumeCount",
                        align: "center",
                        labelWidth: "250",
                    },
                    {
                        type: "function",
                        label: "耗材总额",
                        prop: "consumeTotal",
                        labelWidth: "150",
                        align: "right",
                        callBack(row) {
                            if (row.consumeTotal) {
                                return row.consumeTotal.toLocaleString();
                            } else {
                                return 0;
                            }
                        },
                    },
                    {
                        label: "领取总数",
                        prop: "personalCount",
                        align: "center",
                        labelWidth: "250",
                    },
                    {
                        type: "function",
                        label: "领取总额",
                        prop: "personalTotal",
                        labelWidth: "150",
                        align: "right",
                        callBack(row) {
                            if (row.personalTotal) {
                                return row.personalTotal.toLocaleString();
                            } else {
                                return 0;
                            }
                        },
                    },
                    {
                        label: "操作",
                        type: "slot",
                        labelWidth: "220",
                        slotName: "operation",
                        className: 'text-spacing-reduction'
                    },
                ],
                check: false,
                rowkey: "id",
                isExpandAll: true,
                height: "",
            },
            // 表格数据
            table_data: [],
            // 功能组件
            errorShow: false,
            loadingShow: false,
            // 表头
            backgroundHeader: "#FAFBFC",
            // 表头字体
            headerColor: "#595959",
        };
    },
    computed: {},
    created() {
        this.init();
    },
    mounted() {
        // this.initTableHeight();
    },
    activated() {
        // 在首次挂载、
        // 以及每次从缓存中被重新插入的时候调用
        if (this.$refs.table) {
            this.$refs.table.$refs.multipleTable.doLayout();
        }
    },
    methods: {
        /**
         * 初始化
         * getList  初始化列表
         * clickBtn 查询/重置
         */
        init() {
            //赋值--存储筛选项及滚动条高度
            this.setPageInfo();
            this.listQuery.schoolId = this.$store.state.schoolId;
            this.getDeptlist();
            this.getList();
        },
        savePageInfo(){
            //存储筛选项及滚动条高度
            let recordsList =  new RecordsList()
            recordsList.set(this.$route.name, {
                formData: this.formData,
                listQuery: this.listQuery,
                tableScrollTop: this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop
            })
        },
        setPageInfo(){
            //赋值--存储筛选项及滚动条高度
            let recordsList =  new RecordsList()
            if(recordsList.get(this.$route.name)){
                this.formData = recordsList.get(this.$route.name).formData;
                this.listQuery = recordsList.get(this.$route.name).listQuery;
            }
        },
        setTableScrollHeight(){
            let recordsList =  new RecordsList()
            if(recordsList.get(this.$route.name)){
                setTimeout(()=>{
                    this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop = recordsList.get(this.$route.name).tableScrollTop
                    //移除--存储筛选项及滚动条高度
                    recordsList.remove(this.$route.name) 
                },500)
            }
        },
        getList() {
            this.loadingTable = true;
            const systemModel = new SystemModel();
            systemModel.getDeptList(this.listQuery).then((res) => {
                this.handleRes(res, () => {
                    this.table_data = res.data.data.list;
                    this.total = res.data.data.totalCount;
                    this.totalMoney = res.data.data.total;
                    this.loadingTable = false;
                    this.setTableScrollHeight();
                });
            }).catch(err => {
                this.loadingTable = false;
            })
        },
        getDeptlist() {
            this._fet("/school/schoolOrgan/listByCondition", {
                schoolId: this.$store.state.schoolId,
                organType: "1",
            }).then((res) => {
                console.log(res.data.data, "222222");
                let getDeptList = this.formData.data.findIndex(
                    (e) => e.key == "deptName",
                );
                this.formData.data[getDeptList].list = [];
                res.data.data.list.forEach((item) => {
                    this.formData.data[getDeptList].list.push({
                        label: item.name,
                        value: item.id,
                    });
                });
            });
        },
        clickBtn(type) {
            switch (type.item.fn) {
                case "primary":
                    this.formData.data.forEach((item) => {
                        if (item.key === "deptName") {
                            this.formData.formInline.deptName = item.value;
                            this.listQuery.deptId = item.value;
                        }
                    });

                    this.listQuery.pageNum = 1;
                    this.getList();
                    break;
                case "reset":
                    this.listQuery.pageNum = 1;
                    this.formData.data.forEach((item) => {
                        item.value = "";
                    });
                    (this.listQuery.deptId = ""), this.getList();
                    break;
            }
        },
        /**
         * 操作按钮
         * goAssetMaintenance   资产维护
         * goDepartmentFlow     部门资产流水
         */
        goAssetMaintenance(data) {
            //存储筛选项及滚动条高度
            this.savePageInfo();
            this.$emit("goAssetMaintenance", data);
        },
        goDepartmentFlow(data) {
            //存储筛选项及滚动条高度
            this.savePageInfo();
            this.$emit("goDepartmentFlow", data);
        },
        handleRes(res, fn) {
            if (res.data.code === "200") {
                fn();
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.title {
    width: 100%;
    height: 20px;
    background-color: #ffffff;
    padding: 20px;
    font-size: 20px;
    color: red;
    font-weight: bold;
    margin-bottom: 2px;
}
</style>
