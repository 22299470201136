<!-- 资产管理-部门资产-部门资产流水 -->
<template>
    <div>
        <global-page-back
            detailPageTitle="部门资产流水"
            @handlerGlobalType="clickBackBtn"
        >
            <template #globalPageBackTabs>
                <div class="tabs-list">
                    <div :class="['tabs-item',{ current: activeName === 1 }]" @click="changeTabs(1)">部门领用</div>
                    <div :class="['tabs-item',{ current: activeName === 2 }]" @click="changeTabs(2)">个人领用</div>
                    <div :class="['tabs-item',{ current: activeName === 3 }]" @click="changeTabs(3)">归还明细</div>
                    <div :class="['tabs-item',{ current: activeName === 4 }]" @click="changeTabs(4)">报修明细</div>
                    <div :class="['tabs-item',{ current: activeName === 5 }]" @click="changeTabs(5)">报废明细</div>
                </div>
            </template>
        </global-page-back>
        <DepartmentReceiving v-if="activeName === 1" :assetFlow="assetFlow" />
        <PersonalUse v-if="activeName === 2" :assetFlow="assetFlow" />
        <ReturnFlow v-if="activeName === 3" :assetFlow="assetFlow" />
        <RepairRecord v-if="activeName === 4" :assetFlow="assetFlow" />
        <ScrappingRecords v-if="activeName === 5" :assetFlow="assetFlow" />
    </div>
</template>
<script>
    import DepartmentReceiving from './DepartmentalAssetFlow/DepartmentReceiving.vue';
    import PersonalUse from './DepartmentalAssetFlow/PersonalUse.vue';
    import ReturnFlow from './DepartmentalAssetFlow/ReturnFlow.vue';
    import RepairRecord from './DepartmentalAssetFlow/RepairRecord.vue';
    import ScrappingRecords from './DepartmentalAssetFlow/ScrappingRecords.vue';
    import GlobalPageBack from "@/components/scrollWrapper/Sub/GlobalPageBack/index.vue";
    export default {
        name: "DepartmentalAssetFlowScrollWrapper",
        components: {
            GlobalPageBack,
            DepartmentReceiving,
            PersonalUse,
            ReturnFlow,
            RepairRecord,
            ScrappingRecords
        },
        props: {
            assetFlow: Object
        },
        data() {
            return {
                activeName: 1,
            }
        },
        methods: {
            changeTabs(index) {
                this.activeName = index
            },
            // 返回部门资产
            clickBackBtn(){
                this.$emit('backList')
            },
        }
    }
</script>
<style lang="scss" scoped>
.tabs-list {
    position: relative;
    padding-left: 72px;
    display: flex;
    height: 48px;
    line-height: 48px;

    &:before {
        content: '';
        position: absolute;
        width: 1px;
        height: 24px;
        background: #D4D6D9;
        border-radius: 1px;
        left: 58px;
        top: 12px;
    }

    .tabs-item {
        width: 96px;
        position: relative;
        text-align: center;
        font-size: 15px;
        color: #606C80;
        cursor: pointer;
    }

    .tabs-item.current {
        color: #2B2E33;
        font-weight: bold;

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%);
            height: 3px;
            width: 30px;
            background: #3C7FFF;
            border-radius: 2px;
        }
    }
}
</style>
